.dbg {
	border: 1px solid red;
}

.dbg-g {
	border: 1px solid yellowgreen;
}

main {
	position: relative;
}

.outer-wrapper {
	display: block;
}

.page-header {
	border: 0;

	h1 {
		flex-grow: 1;
		color: black;
	}

	@media screen and (max-width: $screen-md) {
		margin: 20px 0 20px;
		padding-bottom: 9px;
	}
}

.container-bottom-spacer {
	padding-bottom: 15px;
}

.inline-scroll {
	height: 100%;
	overflow-y: auto;
	scrollbar-width: thin;
}

.flex {
	display: flex;
}

.flex-center {
	align-items: center;
}

.flex-h-center {
	justify-content: center;
}

.flex-baseline {
	align-items: baseline;
}

.flex-reversed,
.flex-row-reversed {
	flex-direction: row-reverse;
}

.flex-row {
	flex-direction: row;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-column {
	flex-direction: column;
}

.flex-column-reversed {
	flex-direction: column-reverse;
}

.flex-grow {
	flex-grow: 1;
}

.flex-1 {
	flex: 1;
}

.flex-2 {
	flex: 2;
}

.flex-3 {
	flex: 3;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-flow-wrap {
	flex-flow: wrap;
}

.flex-end {
	justify-content: flex-end;
	justify-items: flex-end;
}

.flex-space-around {
	justify-content: space-around;
}

.flex-space-between {
	justify-content: space-around;
}

.flex-align-end {
	align-items: flex-end;
	align-content: flex-end;
}

.flex-shrink-off {
	flex-shrink: 0;
}

.flex-overflow-off {
	overflow: hidden;
}

.flex-self-end {
	align-self: flex-end;
}

.flex-break {
	flex-basis: 100%;
	width: 100%;
}

dl {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;

	dt {
		flex-basis: 30%;
	}

	dd {
		flex-basis: 70%;
	}

	dt,
	dd {
		display: flex;
		flex-direction: column;
		padding: 5px;
	}
}

.site-title {
	margin-bottom: 0;
	font-size: 5rem;

	@media screen and (max-width: $screen-md) {
		font-size: 4rem;
	}

	color: white;
}

.error {
	color: $specialtext;
}
