breadcrumb {
    display: inherit;
    
    ol.breadcrumb {
        margin-bottom:0px;
        background-color: transparent;

        li + li:before{
            color: $black;
            content: ">";
        }
        li {
            span{
                color: $darkGrey;
            }

            a{
                color: $black;
            }
           
        }
    }
}