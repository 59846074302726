$paddingList: (15px, 30px);

@each $padValue in $paddingList {
  .has-padding-top-#{$padValue} {
    padding-top: #{$padValue};
  }

  .has-padding-right-#{$padValue} {
    padding-right: #{$padValue};
  }

  .has-padding-bottom-#{$padValue} {
    padding-bottom: #{$padValue};
  }

  .has-padding-left-#{$padValue} {
    padding-left: #{$padValue};
  }

  .has-padding-horizontal-#{$padValue} {
    padding: 0 #{$padValue};
  }

  .has-padding-vertical-#{$padValue} {
    padding: #{$padValue} 0;
  }

  .multi-lines {
    white-space: pre-line;
  }
}

.blur {
  filter: blur(3px);
}

.chineseLicense {
  position: fixed;
  bottom: 2px;
  left: 2px;
  z-index: 10000;
  background: #80808061;
  color: white;
  padding: 2px 6px;
  border-radius: 2px;
  font-size: 12px;
}
