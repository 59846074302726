html {
    font-size: 62.5%;
}

body {
    font-size: 1.5em; /* currently ems cause chrome bug misinterpreting rems on body element */
    line-height: 1.6;
    font-weight: 400;
    font-family: "FuturaLight", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, "Noto Sans", sans-serif;
    color: #282828;
}

.font-medium,
.medium-font {
    font-family: "FuturaMedium", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, "Noto Sans Medium", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 2rem;
    font-weight: 300;
}

h1 {
    font-size: 4rem;
    line-height: 1.2;
    letter-spacing: 0;
}

h2 {
    font-size: 3.6rem;
    line-height: 1.25;
    letter-spacing: 0;
}

h3 {
    font-size: 3rem;
    line-height: 1.3;
    letter-spacing: 0;
}

h4 {
    font-size: 2.4rem;
    line-height: 1.35;
    letter-spacing: 0;
}

h5 {
    font-size: 1.8rem;
    line-height: 1.5;
    letter-spacing: 0;
}

h6 {
    font-size: 1.5rem;
    line-height: 1.6;
    letter-spacing: 0;
}

/* Larger than phablet */
@media (min-width: 550px) {
    h1 { font-size: 5rem; }
    h2 { font-size: 4.2rem; }
    h3 { font-size: 3.6rem; }
    h4 { font-size: 3rem; }
    h5 { font-size: 2.4rem; }
    h6 { font-size: 1.5rem; }
}

p {
    font-weight: 200;
    margin-top: 0;
}

b {
    @extend .font-medium;

    font-weight: normal;
}

a {
    &.text-muted {
        &:hover {
            text-decoration: none;
            color: white;
        }
    }
}

.lindbergfont {
    font-family: 'LindbergLight', "FuturaLight", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, "Noto Sans", sans-serif;
    letter-spacing: 0;

    &.font-medium {
        font-family: 'LindbergMedium', "FuturaLight", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, "Noto Sans", sans-serif;
    }
}
