@import './../common/vars';

.form-control {
    &:focus {
        box-shadow: none;
    }
}

.has-error {
    .form-control {
        border-color: $brand-danger;
    }

    .input-group-addon {
        border-color: $brand-danger;
    }
}

.customizer-input {
    & > input,
    > textarea {
        border: 1px solid $input-border;
        width: 100%;
        outline: none;
        padding: 5px 10px;
        background-color: white;
        resize: none;
        border-radius: 3px;

        &:focus {
            outline: none;
        }

        &::-webkit-input-placeholder {
            color: $lightGrey;
            text-transform: none;
        }

        &::-moz-placeholder {
            color: $lightGrey;
            text-transform: none;
        }

        &:-ms-input-placeholder {
            color: $lightGrey;
            text-transform: none;
        }

        &:-moz-placeholder {
            color: $lightGrey;
            text-transform: none;
        }
    }
}


input {
    &:disabled {
        color: $disabledInputColor;
    }
}

.input-group {
    .input-group-addon {
        color: $lightGrey;
        background-color: $input-bg;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }

    .form-control {
        border-left-width: 0;
        border-right-width: 0;

        &:first-child {
            border-left-width: 1px;
        }

        &:last-child {
            border-right-width: 1px;
        }
    }
}
