@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  #{calc(100/74)*8}% {
    transform: rotate(0deg);
  }
  #{calc(100/74)*9}% {
    transform: rotate(-1.7deg);
  }
  #{calc(100/74)*10}%{
    transform: rotate(-4.8deg);
  }
  #{calc(100/74)*11}%{
    transform: rotate(-8.3deg);
  }
  #{calc(100/74)*12}%{
    transform: rotate(-11.7deg);
  }
  #{calc(100/74)*13}% {
    transform: rotate(-14.8deg);
  }
  #{calc(100/74)*14}% {
    transform: rotate(-17.3deg);
  }
  #{calc(100/74)*15}%{
    transform: rotate(-19.3deg);
  }
  #{calc(100/74)*16}%{
    transform: rotate(-20.6deg);
  }
  #{calc(100/74)*17}%{
    transform: rotate(-21deg);
  }
  #{calc(100/74)*18}% {
    transform: rotate(0deg);
  }
  #{calc(100/74)*19}% {
    transform: rotate(13.6deg);
  }
  #{calc(100/74)*20}% {
    transform: rotate(50.8deg);
  }
  #{calc(100/74)*21}% {
    transform: rotate(105.2deg);
  }
  #{calc(100/74)*22}%{
    transform: rotate(168.8deg);
  }
  #{calc(100/74)*23}% {
    transform: rotate(232.5deg);
  }
  #{calc(100/74)*24}% {
    transform: rotate(286.9deg);
  }
  #{calc(100/74)*25}% {
    transform: rotate(332.8deg);
  }
  #{calc(100/74)*26}% {
    transform: rotate(376.6deg);
  }
  #{calc(100/74)*27}% {
    transform: rotate(418.3deg);
  }
  #{calc(100/74)*28}% {
    transform: translate(457.6deg);
  }
  #{calc(100/74)*29}%{
    transform: rotate(494.4deg);
  }
  #{calc(100/74)*30}% {
    transform: rotate(528.7deg);
  }
  #{calc(100/74)*31}% {
    transform: rotate(560.4deg);
  }
  #{calc(100/74)*32}%{
    transform: rotate(589.4deg);
  }
  #{calc(100/74)*33}% {
    transform: rotate(615.7deg);
  }
  #{calc(100/74)*34}%{
    transform: rotate(639.4deg);
  }
  #{calc(100/74)*35}%{
    transform: rotate(660.3deg);
  }
  #{calc(100/74)*36}%{
    transform: rotate(678.6deg);
  }
  #{calc(100/74)*37}%{
    transform: rotate(694,2deg);
  }
  #{calc(100/74)*38}%{
    transform: rotate(707.3deg);
  }
  #{calc(100/74)*39}%{
    transform: rotate(717.8deg);
  }
  #{calc(100/74)*40}%{
    transform: rotate(725.9deg);
  }
  #{calc(100/74)*41}%{
    transform: rotate(731.5deg);
  }
  #{calc(100/74)*42}%{
    transform: rotate(734.9deg);
  }
  #{calc(100/74)*43}%{
    transform: rotate(736deg);
  }
  #{calc(100/74)*44}%{
    transform: rotate(735.9deg);
  }
  #{calc(100/74)*45}%{
    transform: rotate(735.4deg);
  }
  #{calc(100/74)*46}%{
    transform: rotate(734.8deg);
  }
  #{calc(100/74)*47}%{
    transform: rotate(733.9deg);
  }
  #{calc(100/74)*48}%{
    transform: rotate(732.8deg);
  }
  #{calc(100/74)*49}%{
    transform: rotate(731.5deg);
  }
  #{calc(100/74)*50}%{
    transform: rotate(730.1deg);
  }
  #{calc(100/74)*51}%{
    transform: rotate(728.5deg);
  }
  #{calc(100/74)*52}%{
    transform: rotate(726.8deg);
  }
  #{calc(100/74)*53}%{
    transform: rotate(725.1deg);
  }
  #{calc(100/74)*54}%{
    transform: rotate(723.3deg);
  }
  #{calc(100/74)*55}%{
    transform: rotate(721.6deg);
  }
  #{calc(100/74)*56}%{
    transform: rotate(720deg);
  }
  #{calc(100/74)*57}%{
    transform: rotate(718.7deg);
  }
  #{calc(100/74)*58}%{
    transform: rotate(718deg);
  }
  #{calc(100/74)*59}%{
    transform: rotate(718.2deg);
  }
  #{calc(100/74)*60}%{
    transform: rotate(718.5deg);
  }
  #{calc(100/74)*61}%{
    transform: rotate(718.8deg);
  }
  #{calc(100/74)*62}%{
    transform: rotate(719.2deg);
  }
  #{calc(100/74)*63}%{
    transform: rotate(719.5deg);
  }
  #{calc(100/74)*64}%{
    transform: rotate(719.8deg);
  }
  #{calc(100/74)*65}%{
    transform: rotate(720deg);
  }
  99.99999999999%{
    transform: rotate(720deg);
  }
  100%{
    transform: rotate(0deg);
  }
}
