.box-size {
    width: 1rem;
    height: 1rem;
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    margin: 0 2px;

    &.circle {
        border-radius: 50%;
    }
}

.font-medium {
    .box-size {
        border-width: 2px;
    }
}

.summary-container {
    table {
        .box-size {
            width: 1.3rem !important;
            height: 1.3rem !important;
        }
    }
}

.model-item-text .box-size {
    margin: 0 1px;
}

h1 .box-size {
    width: 4rem;
    height: 4rem;
}

h2 .box-size {
    width: 3.6rem;
    height: 3.6rem;
}

h3 .box-size {
    width: 3rem;
    height: 3rem;
}

h4 .box-size {
    width: 2.4rem;
    height: 2.4rem;
}

h5 .box-size {
    width: 1.8rem;
    height: 1.8rem;
}

h6 .box-size {
    width: 1.5rem;
    height: 1.5rem;
}

@media (min-width: 550px) {
    h1 .box-size {
        width: 5rem;
        height: 5rem;
    }

    h2 .box-size {
        width: 4.2rem;
        height: 4.2rem;
    }

    h3 .box-size {
        width: 3.6rem;
        height: 3.6rem;
    }

    h4 .box-size {
        height: 3rem;
        width: 3rem;
    }

    h5 .box-size {
        width: 2.4rem;
        height: 2.4rem;
    }

    h6 .box-size {
        width: 1.5rem;
        height: 1.5rem;
    }
}