.product-item {
    padding: 3px !important;

    ordering-info {
        position: absolute;
        right: 1.5rem;
        text-align: right;
        bottom: 2em;
        font-size: 1.3em;
        z-index: 1;
        color: $lightGrey;
        background-color: transparent;
    }

    availability-text {
        position: absolute;
        text-align: center;
        top: 1rem;
        z-index: 1;
        background-color: transparent;
        width: 100%;
        .discontinued {
            color: $lightGrey;
        }
    }

    > div {
        padding-top: 62%;
        background-color: $product-background;
    }

    dcg-image,
    dcg-image .product-image,
    .product-image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        width: 100%;

        img {
            width: 100%;
            transform: scale(1.05);
        }
        img:active {
            transform: scale(1);
        }

        &.garniture {
            padding: 0 50px;
            height: auto;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    span.demo-garniture {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 1rem 1.5rem;

        p {
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }

    a {
        color: $main-color;
        cursor: pointer;
        position: absolute;
        width: 3.5rem;
        bottom: 0rem;
        left: 1.5rem;
        right: 1.5rem;
        text-align: left;
        font-size: 2.2rem;
        z-index: 998;
        i {
            font-size: 3.5rem;
        }
    }
}
