@import "./../../../node_modules/ngx-ui-switch/ui-switch.component.scss";

ui-switch{
    .switch{
        background: white;
    }

    .switch-small[_ngcontent-c1] small[_ngcontent-c1] {
        width: 18px !important;
        height: 18px !important;
        margin-top: 1px !important;
        margin-left: 1px !important;
    }

    .checked[_ngcontent-c1] {
        background: $lindbergBlue !important;
        border-color: $lindbergBlue !important;
    }
    .switch-small.checked[_ngcontent-c1] small[_ngcontent-c1] {
        left: 20px !important;
    }
    .switch-small[_ngcontent-c1] {
        width: 40px !important;
    }
}

.ui-switch{
    .switch-text{
        margin-left: 5px;
    }
}