button{
  label {
    cursor: pointer;
  }
}

.btn.disabled, .btn[disabled]  {
    opacity: 0.3;
}

.btn-cta,
.btn-primary,
.btn-secondary {
  font-family: "FuturaMedium";
  font-size: 14px;
  letter-spacing: 0.32px;
  padding: 1.2rem;
  white-space: normal;
}

.btn-icon{
  width: 34px;
  height: 34px;
  font-size: 16px !important;
  line-height: 0 !important;
  padding: 10px !important;
}


.btn-short{
  line-height:1 !important;
  height: 34px !important;
}

.btn-xs{
  padding: 5px 10px !important;
  font-size: 12px !important;
}
.btn-sm{
  padding: 5px 10px !important;
  font-size: 14px !important;
  height: 40px;
}

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
  outline: unset !important;
}
/* CTA button start */
.btn-cta {
  background-color: $cta !important;
  color: #ffffff !important;
  border: 0px !important;
}
.btn-cta:hover {
  background-color: $cta-hover !important;
}
.btn-cta:active, .btn-cta.active {
  background-color: $cta-active !important;
  box-shadow: unset !important;
}
.btn-cta:disabled, .btn-cta.disabled {
  background-color: $cta !important;
  opacity: 0.3;
}

/* primary button start */
.btn-primary {
  background-color: $tg-700 !important;
  border: 0px !important;
  color: #ffffff !important;
}
.btn-primary:hover {
  background-color: #707070 !important;
}
.btn-primary:active, .btn-primary.active {
  background-color: #565656 !important;
  box-shadow: unset !important;
}
.btn-primary:disabled, .btn-primary.disabled {
  background-color: #565656 !important;
  opacity: 0.3;
}
.btn-primary.toggled {
  background-color: #575757;
  box-shadow: inset 0 30px 30px -20px rgb(0 0 0 / 75%);
}
/* primary button end */


/* secondary button start */
.btn-secondary {
  background-color: transparent !important;
  border: 1px solid #4B4B4B;
  color: #4B4B4B !important;
}
.btn-secondary:hover {
  background-color:hsla(0,0%,100%,.5) !important;
  color: #4B4B4B !important;
}
.btn-secondary:active, .btn-secondary.active {
  background-color: rgba(0,0,0,.2) !important;
  border: 1px solid #4B4B4B;
  color: #4B4B4B !important;
  box-shadow: unset !important;
}
.btn-secondary:disabled, .btn-secondary.disabled {
  border: 1px solid #4B4B4B !important;
  opacity: 0.3;
  color: #4B4B4B !important;
}
.btn-secondary.toggled {
  background-color: rgba(0,0,0,.02) !important;
  border: 1px solid #4B4B4B;
  color: #4B4B4B !important;
  box-shadow: inset 0 30px 30px -20px rgb(0 0 0 / 20%);
  border-color: rgba(75,75,75,.4);
}
/* secondary button end */
