$fontPath: '/assets/fonts/';
$fontFamilies: 'FuturaLight','FuturaMedium','LindbergLight','LindbergMedium';
$fontFilenames: 'futura-light-web','futura-medium-web','LINDBERG-light-web','LINDBERG-medium-web';
$i: 1;

@each $fontFamily in $fontFamilies {
  @font-face {
    font-family: $fontFamily;
    font-display: fallback;
    src: url($fontPath + nth($fontFilenames, $i) + '.eot?') format('embedded-opentype'), 
      url($fontPath + nth($fontFilenames, $i) + '.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  $i: $i + 1;
}

@font-face{
    font-family: "Noto Sans Medium";
    src: url($fontPath + 'NotoSansCJKsc-Medium.otf') format('opentype');
    font-weight: normal;
}

@font-face{
    font-family: "Noto Sans";
    src: url($fontPath + 'NotoSansCJKsc-Thin.otf') format('opentype');
    font-weight: normal;
}