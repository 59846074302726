*,
*::after,
*::before {
	box-sizing: border-box;
	position: relative;
}

ldcg-app {
	display: block;
	position: static;
}

html,
body {
	width: 100%;
	height: 100%;
	overflow: hidden;
	overscroll-behavior: none;
	-webkit-overflow-scrolling: none;
}

body:not(.is-touchdevice) {

	.menu:hover,
	.submenu:hover {
		background-color: $settingsBackground !important;
	}
}

html.nav-open,
.nav-open body {
	overflow-x: hidden;
	overflow-y: hidden;
	-webkit-overflow-scrolling: auto;
}

body {
	display: flex;
	background-color: $bodyBackground;

	-webkit-touch-callout: none;
	-webkit-overflow-scrolling: none;
}

a.mainlink {
	font-family: "FuturaMedium";
	color: $cta;
}

a.mainlink:hover {
	font-family: "FuturaMedium";
	color: $cta-hover;
}

ldcg-app {
	width: 100%;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	// For at forhindre 'over scroll' på iOS. Skal testes for at validere 100%

	-webkit-overflow-scrolling: none;
	overscroll-behavior: none;
}

main {
	overflow: auto;
	transition: transform 1s;
	z-index: 100;
	flex-grow: 1;
	-webkit-overflow-scrolling: none;
	height: 100%;
}

.prevent-scroll {
	-webkit-overflow-scrolling: none;
}

#page-wrapper {
	flex-grow: 1;
	-webkit-overflow-scrolling: touch;
	background-color: $main-color;

	> :last-child {
		// windows 8.1 fix
		// display: block;
		height: 100%;
	}

	.page {
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: auto;
		background-color: $pageBackground;

		page-header {
			padding: 0 0 10px 0px;

			.site-title {
				color: $black;
			}
		}

		.content-page {
			color: $black;
			flex-grow: 1;
			flex-shrink: 0;
			margin-bottom: 40px;

			.max-width {
				max-width: 1024px;
				padding-left: 15px;
				padding-right: 15px;
				width: 100%;
				min-width: 320px;
				align-self: center;
			}
		}

		.content-page-header {
			width: 100%;
			background-color: $pageContentBackground;
		}

		.top-container {
			width: 100%;
		}

		.content-container {
			display: flex;
			justify-content: space-between;
			color: $black;
			margin: 20px 10% 0px 10%;
			padding: 20px;
		}

		.top-border {
			border-top: 1px solid $lightSeparatorColor;
		}
	}
}

.clearfix {
	clear: both;
}

.nav-open main {
	transform: translateX(29rem);
}

img {
	height: auto;
	display: block;
}

input::placeholder,
textarea::placeholder {
	color: $placeholder !important;
}

.blur {
	filter: blur(5px);
}

input::-ms-clear {
	display: none;
}

label {
	font-weight: normal;
}

.red {
	color: red;
}

.product-image,
.model-item {
	transition: filter 0.4s;

	&:hover {
		transition: filter 0s;
	}
}

.product-image:hover {
	filter: brightness(1.08);
}

.model-item:hover {
	filter: contrast(1.08);
}

icon-sync.sync-state-1 {
	fill: $success;
	-webkit-animation-name: spin;
	-webkit-animation-duration: 1500ms;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	-moz-animation-name: spin;
	-moz-animation-duration: 1500ms;
	-moz-animation-iteration-count: infinite;
	-moz-animation-timing-function: linear;
	-ms-animation-name: spin;
	-ms-animation-duration: 1500ms;
	-ms-animation-iteration-count: infinite;
	-ms-animation-timing-function: linear;
	animation-name: spin;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

icon-sync.sync-state-2 {
	fill: $warning;
}

icon-sync.sync-state-3 {
	fill: $danger;
}

@-ms-keyframes spin {
	from {
		-ms-transform: scale(-1, 1) rotate(360deg);
	}

	to {
		-ms-transform: scale(-1, 1) rotate(0deg);
	}
}

@-moz-keyframes spin {
	from {
		-moz-transform: scale(-1, 1) rotate(360deg);
	}

	to {
		-moz-transform: scale(-1, 1) rotate(0deg);
	}
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: scale(-1, 1) rotate(360deg);
	}

	to {
		-webkit-transform: scale(-1, 1) rotate(0deg);
	}
}

@keyframes spin {
	from {
		transform: scale(-1, 1) rotate(360deg);
	}

	to {
		transform: scale(-1, 1) rotate(0deg);
	}
}

.muted {
	opacity: 0.5;
}

.text-italic {
	font-style: italic;
}

.text-bold {
	font-family: "FuturaMedium";
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.pointer {
	cursor: pointer;
}

.no-pointer {
	cursor: default !important;
}

.customizer-colors-container {
	.information-icon {
		top: 55px !important;
		right: 12px !important;
	}
}

.my-collection {
	.not-favorite {
		opacity: 75% !important;

		.c-heart {
			fill: $darkGrey !important;
		}
	}

	.not-favorite:hover {
		opacity: 100% !important;
	}

	svg {
		height: 40px !important;
		width: 40px !important;
	}
}

.text-cta {
	color: $cta;
}

.from-featured-model {
	.collections-container {
		max-height: 235px !important;
	}
}
