@mixin menu-border($side, $color) {
  border-#{$side}: 1px solid $color;
}

@mixin menu-shadow($position) {
  @if $position == 'top' {
    box-shadow: 0 -0.2rem 1rem $shadowColor;
  }
  @else if $position == 'right' {
    box-shadow: 0.3rem 0 1rem $shadowColor;
  }
  @else if $position == 'bottom' {
    box-shadow: 0 0.2rem 1rem $shadowColor;
  }
  @else if $position == 'left' {
    box-shadow: -0.3rem 0 1rem $shadowColor;
  }
  @else if $position == 'top-bottom' {
    box-shadow: 0 0rem 1.5rem #7f7f7f;
  }
}

@mixin menu-shadow-precious($position) {
  @if $position == 'top' {
    box-shadow: 0 -0.2rem 1rem $shadow-color-precious !important;
  }
  @else if $position == 'right' {
    box-shadow: 0.3rem 0 1rem $shadow-color-precious !important;
  }
  @else if $position == 'bottom' {
    box-shadow: 0 0.2rem 1rem $shadow-color-precious !important;
  }
  @else if $position == 'left' {
    box-shadow: -0.3rem 0 1rem $shadow-color-precious !important;
  }
  @else if $position == 'top-bottom' {
    box-shadow: 0 0rem 1.5rem $shadow-color-precious !important;
  }
}

@mixin item-border($color) {
  &:first-child{
    border-top: 1px solid $color;
  }
  border-bottom: 1px solid $color;
}

@mixin item-border-top($color) {
    border-top: 1px solid $color;
}

@mixin hover-support {
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            @content;
        }
    }
}