// paddings:
.px-0 {
    padding-left: $spacers * 0;
    padding-right: $spacers * 0;
}

.py-0 {
    padding-top: $spacers * 0;
    padding-bottom: $spacers * 0;
}

.pl-0 {
    padding-left: $spacers * 0;
}

.pr-0 {
    padding-right: $spacers * 0;
}

.pt-0 {
    padding-top: $spacers * 0;
}

.pb-0 {
    padding-bottom: $spacers * 0;
}

.p-0 {
    padding: $spacers * 0;
}

// -----------------

.px-1 {
    padding-left: $space-xxs;
    padding-right: $space-xxs;
}

.py-1 {
    padding-top: $space-xxs;
    padding-bottom: $space-xxs;
}

.pl-1 {
    padding-left: $space-xxs;
}

.pr-1 {
    padding-right: $space-xxs;
}

.pt-1 {
    padding-top: $space-xxs;
}

.pb-1 {
    padding-bottom: $space-xxs;
}

.p-1 {
    padding: $space-xxs;
}

// -----------------

.px-2 {
    padding-left: $space-xs;
    padding-right: $space-xs;
}

.py-2 {
    padding-top: $space-xs;
    padding-bottom: $space-xs;
}

.pl-2 {
    padding-left: $space-xs;
}

.pr-2 {
    padding-right: $space-xs;
}

.pt-2 {
    padding-top: $space-xs;
}

.pb-2 {
    padding-bottom: $space-xs;
}

.p-2 {
    padding: $space-xs;
}

// -----------------

.px-3 {
    padding-left: $space-s;
    padding-right: $space-s;
}

.py-3 {
    padding-top: $space-s;
    padding-bottom: $space-s;
}

.pl-3 {
    padding-left: $space-s;
}

.pr-3 {
    padding-right: $space-s;
}

.pt-3 {
    padding-top: $space-s;
}

.pb-3 {
    padding-bottom: $space-s;
}

.p-3 {
    padding: $space-s;
}

// -----------------

.px-4 {
    padding-left: $space-m;
    padding-right: $space-m;
}

.py-4 {
    padding-top: $space-m;
    padding-bottom: $space-m;
}

.pl-4 {
    padding-left: $space-m;
}

.pr-4 {
    padding-right: $space-m;
}

.pt-4 {
    padding-top: $space-m;
}

.pb-4 {
    padding-bottom: $space-m;
}

.p-4 {
    padding: $space-m;
}

// -----------------


.px-5 {
    padding-left: $space-l;
    padding-right: $space-l;
}

.py-5 {
    padding-top: $space-l;
    padding-bottom: $space-l;
}

.pl-5 {
    padding-left: $space-l;
}

.pr-5 {
    padding-right: $space-l;
}

.pt-5 {
    padding-top: $space-l;
}

.pb-5 {
    padding-bottom: $space-l;
}

.p-5 {
    padding: $space-l;
}


.px-6 {
    padding-left: $space-xl;
    padding-right: $space-xl;
}

.py-6 {
    padding-top: $space-xl;
    padding-bottom: $space-xl;
}

.pl-6 {
    padding-left: $space-xl;
}

.pr-6 {
    padding-right: $space-xl;
}

.pt-6 {
    padding-top: $space-xl;
}

.pb-6 {
    padding-bottom: $space-xl;
}

.p-6 {
    padding: $space-xl;
}

.px-7 {
    padding-left: $space-xl;
    padding-right: $space-xl;
}

.py-7 {
    padding-top: $space-xl;
    padding-bottom: $space-xl;
}

.pl-7 {
    padding-left: $space-xl;
}

.pr-7 {
    padding-right: $space-xl;
}

.pt-7 {
    padding-top: $space-xl;
}

.pb-7 {
    padding-bottom: $space-xl;
}

.p-7 {
    padding: $space-xl;
}

.px-8 {
    padding-left: $space-xxl;
    padding-right: $space-xxl;
}

.py-8 {
    padding-top: $space-xxl;
    padding-bottom: $space-xxl;
}

.pl-8 {
    padding-left: $space-xxl;
}

.pr-8 {
    padding-right: $space-xxl;
}

.pt-8 {
    padding-top: $space-xxl;
}

.pb-8 {
    padding-bottom: $space-xxl;
}

.p-8 {
    padding: $space-xxl;
}

// Margins:

.mx-0 {
    margin-left: $spacers * 0;
    margin-right: $spacers * 0;
}

.my-0 {
    margin-top: $spacers * 0;
    margin-bottom: $spacers * 0;
}

.ml-0 {
    margin-left: $spacers * 0;
}

.mr-0 {
    margin-right: $spacers * 0;
}

.mt-0 {
    margin-top: $spacers * 0;
}

.mb-0 {
    margin-bottom: $spacers * 0;
}

.m-0 {
    margin: $spacers * 0;
}

// -----------------

.mx-1 {
    margin-left: $space-xxs;
    margin-right: $space-xxs;
}

.my-1 {
    margin-top: $space-xxs;
    margin-bottom: $space-xxs;
}

.ml-1 {
    margin-left: $space-xxs;
}

.mr-1 {
    margin-right: $space-xxs;
}

.mt-1 {
    margin-top: $space-xxs;
}

.mb-1 {
    margin-bottom: $space-xxs;
}

.m-1 {
    margin: $space-xxs;
}

// -----------------

.mx-2 {
    margin-left: $space-xs;
    margin-right: $space-xs;
}

.my-2 {
    margin-top: $space-xs;
    margin-bottom: $space-xs;
}

.ml-2 {
    margin-left: $space-xs;
}

.mr-2 {
    margin-right: $space-xs;
}

.mt-2 {
    margin-top: $space-xs;
}

.mb-2 {
    margin-bottom: $space-xs;
}

.m-2 {
    margin: $space-xs;
}

// -----------------

.mx-3 {
    margin-left: $space-s;
    margin-right: $space-s;
}

.my-3 {
    margin-top: $space-s;
    margin-bottom: $space-s;
}

.ml-3 {
    margin-left: $space-s;
}

.mr-3 {
    margin-right: $space-s;
}

.mt-3 {
    margin-top: $space-s;
}

.mb-3 {
    margin-bottom: $space-s;
}

.m-3 {
    margin: $space-s;
}

// -----------------

.mx-4 {
    margin-left: $space-m;
    margin-right: $space-m;
}

.my-4 {
    margin-top: $space-m;
    margin-bottom: $space-m;
}

.ml-4 {
    margin-left: $space-m;
}

.mr-4 {
    margin-right: $space-m;
}

.mt-4 {
    margin-top: $space-m;
}

.mb-4 {
    margin-bottom: $space-m;
}

.m-4 {
    margin: $space-m;
}

// -----------------


.mx-5 {
    margin-left: $space-l;
    margin-right: $space-l;
}

.my-5 {
    margin-top: $space-l;
    margin-bottom: $space-l;
}

.ml-5 {
    margin-left: $space-l;
}

.mr-5 {
    margin-right: $space-l;
}

.mt-5 {
    margin-top: $space-l;
}

.mb-5 {
    margin-bottom: $space-l;
}

.m-5 {
    margin: $space-l;
}

.mx-6 {
    margin-left: $space-xl;
    margin-right: $space-xl;
}

.my-6 {
    margin-top: $space-xl;
    margin-bottom: $space-xl;
}

.ml-6 {
    margin-left: $space-xl;
}

.mr-6 {
    margin-right: $space-xl;
}

.mt-6 {
    margin-top: $space-xl;
}

.mb-6 {
    margin-bottom: $space-xl;
}

.m-7 {
    margin: $space-xl;
}

.mx-7 {
    margin-left: $space-xl;
    margin-right: $space-xl;
}

.my-7 {
    margin-top: $space-xl;
    margin-bottom: $space-xl;
}

.ml-7 {
    margin-left: $space-xl;
}

.mr-7 {
    margin-right: $space-xl;
}

.mt-7 {
    margin-top: $space-xl;
}

.mb-7 {
    margin-bottom: $space-xl;
}

.m-7 {
    margin: $space-xl;
}

.mx-8 {
    margin-left: $space-xl;
    margin-right: $space-xl;
}

.my-8 {
    margin-top: $space-xl;
    margin-bottom: $space-xl;
}

.ml-8 {
    margin-left: $space-xl;
}

.mr-8 {
    margin-right: $space-xl;
}

.mt-8 {
    margin-top: $space-xl;
}

.mb-8 {
    margin-bottom: $space-xl;
}

.m-8 {
    margin: $space-xl;
}
// -----------------

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.mt-auto {
    margin-top: auto;
}

.mb-auto {
    margin-bottom: auto;
}

.m-auto {
    margin: auto;
}

// ----------------

.mx-n1 {
    margin-left: $space-xxs * -1;
    margin-right: $space-xxs * -1;
}

.my-n1 {
    margin-top: $space-xxs * -1;
    margin-bottom: $space-xxs * -1;
}

.ml-n1 {
    margin-left: $space-xxs * -1;
}

.mr-n1 {
    margin-right: $space-xxs * -1;
}

.mt-n1 {
    margin-top: $space-xxs * -1;
}

.mb-n1 {
    margin-bottom: $space-xxs * -1;
}

.m-n1 {
    margin: $space-xxs * -1;
}

// -----------------

.mx-n2 {
    margin-left: $space-xs * -1;
    margin-right: $space-xs * -1;
}

.my-n2 {
    margin-top: $space-xs * -1;
    margin-bottom: $space-xs * -1;
}

.ml-n2 {
    margin-left: $space-xs * -1;
}

.mr-n2 {
    margin-right: $space-xs * -1;
}

.mt-n2 {
    margin-top: $space-xs * -1;
}

.mb-n2 {
    margin-bottom: $space-xs * -1;
}

.m-n2 {
    margin: $space-xs * -1;
}

// -----------------

.mx-n3 {
    margin-left: $space-s * -1;
    margin-right: $space-s * -1;
}

.my-n3 {
    margin-top: $space-s * -1;
    margin-bottom: $space-s * -1;
}

.ml-n3 {
    margin-left: $space-s * -1;
}

.mr-n3 {
    margin-right: $space-s * -1;
}

.mt-n3 {
    margin-top: $space-s * -1;
}

.mb-n3 {
    margin-bottom: $space-s * -1;
}

.m-n3 {
    margin: $space-s * -1;
}

// -----------------

.mx-n4 {
    margin-left: $space-m * -1;
    margin-right: $space-m * -1;
}

.my-n4 {
    margin-top: $space-m * -1;
    margin-bottom: $space-m * -1;
}

.ml-n4 {
    margin-left: $space-m * -1;
}

.mr-n4 {
    margin-right: $space-m * -1;
}

.mt-n4 {
    margin-top: $space-m * -1;
}

.mb-n4 {
    margin-bottom: $space-m * -1;
}

.m-n4 {
    margin: $space-m * -1;
}

// -----------------


.mx-n5 {
    margin-left: $space-l * -1;
    margin-right: $space-l * -1;
}

.my-n5 {
    margin-top: $space-l * -1;
    margin-bottom: $space-l * -1;
}

.ml-n5 {
    margin-left: $space-l * -1;
}

.mr-n5 {
    margin-right: $space-l * -1;
}

.mt-n5 {
    margin-top: $space-l * -1;
}

.mb-n5 {
    margin-bottom: $space-l * -1;
}

.m-n5 {
    margin:  $space-l * -1;
}