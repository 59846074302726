// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$lindberg-app-primary: mat.define-palette(mat.$indigo-palette);
$lindberg-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$lindberg-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$lindberg-app-theme: mat.define-light-theme((color: (primary: $lindberg-app-primary,
	accent: $lindberg-app-accent,
	warn: $lindberg-app-warn,
)));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($lindberg-app-theme);

$my-app-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-app-accent: mat.define-palette(mat.$indigo-palette, 500, 900, A100);
$my-app-warn: mat.define-palette(mat.$indigo-palette);

$my-app-theme: mat.define-light-theme((color: (primary: $my-app-primary,
		accent: $my-app-accent,
		warn: $my-app-warn,
	),
	typography: mat.define-typography-config(),
	density: 0,
));

// @include angular-material-theme($my-app-theme);

@import "sass/base/all",
"sass/common/animation",
"sass/common/helpers";

html,
body {
	height: 100%;
}

// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }