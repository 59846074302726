$icon-font-path: '/assets/fonts/bootstrap/' !default;

// Static distances
$menuWidth: 12rem;
$menuWideWidth: 368px;
$settingsWidth: 30rem;
$mobileWidth: 43rem;
$tabletBreakpoint: 1024px;


// New color scheme
//// Titanium Grey (TG)
$tg-50: #FBFBFB;
$tg-100: #F6F6F6;
$tg-200: #F1F1F1;
$tg-250: #E8E8E8;
$tg-300: #D9D9D9;
$tg-350: #CECECE;
$tg-400: #C3C3C3;
$tg-500: #A5A5A5;
$tg-600: #7B7B7B;
$tg-700: #676767;
$tg-800: #474747;
$tg-900: #262626;
//// Titanium Blue Grey (TBG)
$tbg-100: #ECEFF3;
$tbg-200: #D1D8DF;
$tbg-250: #B4BEC9;
$tbg-300: #96A4B3;
$tbg-350: #96A4B3;
$tbg-400: #697D91;
$tbg-500: #5B6E80;
$tbg-600: #4B5B69;
$tbg-700: #3C4854;
$tbg-800: #323C45;
$tbg-900: #2A333C;
//// Titanium Primary (TP)
$tp-100: #E5E9F0;
$tp-200: #BEC8DB;
$tp-300: #95A5C2;
$tp-400: #6C82A9;
$tp-500: #4C6899;
$tp-600: #294F8A;
$tp-700: #234882;
$tp-800: #193F76;
$tp-900: #12356A;
$tp-925: #26354D;
$tp-950: #0E1E39;
//// Precious Primary (PP)
$pp-100: #C0B092;
$pp-200: #BEAB8A;
$pp-300: #BCA582;
$pp-350: #BAA07A;
$pp-400: #A8916F;
$pp-500: #968365;
$pp-600: #85745B;
$pp-700: #746650;
$pp-800: #645847;
$pp-900: #544B3D;
//// Precious Grey (PG)
$pg-50: #0C0B0A;
$pg-100: #161514;
$pg-200: #1E1D1C;
$pg-250: #252524;
$pg-300: #2E2D2C;
$pg-350: #363534;
$pg-400: #4B4A49;
$pg-500: #7C7C7C;
$pg-600: #A5A5A5;
$pg-700: #C4C4C4;
$pg-800: #DDDDDD;
$pg-900: #F6F6F6;

$ps-200: #F3E2C2;
$ps-400: #C0B092;

// Colors
$white: #ffffff;
$black: #000000;
$lindbergBlue: #080C41;
$success: #3B863A;
$warning: #F6C344;
$danger: #C0352C;
$notification: #C0352C;
$brand-danger: #C0352C;
$state-danger-border: $brand-danger;
$cta: $tp-800;
$link-color: $tg-600;
$cta-hover: $tp-600;
$cta-active: $tp-900;
$input-border: $tp-100;
$main-color:$tbg-900;
$secondary-color: $tg-250;
$tertiary-color: $tg-400;
$selected: $white;
$specialtext: $brand-danger;
$darkGrey: $tg-800;
$lightGrey: $tg-600;
$disabledToogleButton: $tg-400;
$disabledInputColor: $tg-500;
$activeButton: $tg-500;
$input-border-focus: $input-border;

// Precious Colors
$lindbergBlue-precious: $pp-350;
$cta-precious: $pp-350;
$cta-precious-hover: $pp-100;
$cta-precious-active: $pp-500;
$main-color-precious: $pg-50;
$secondary-color-precious: $pg-100;
$selected-precious: $pp-300;
$top-nav-precious: $pg-250;
$dark-background-precious: $pg-100;
$product-background-precious: $pg-100;
$group-background-color-precious: $pg-100;
$menu-button-background-precious-3: $pp-900;
$menu-background-border-precious: $pp-900;
$light-separator-color-precious: $pg-350;
$medium-separator-color-precious: $pp-900;
$group-font-color-precious: $pp-350;
$shadow-color-precious: $selected-precious;
$mutedText-precious: $pg-500;


// Backgrounds
$headerBackground: $white;
$large-image-background: $tg-100;
$settingsBackground: $tg-200;
$pageBackground: $tg-200;
$bodyBackground: $tg-250;
$ordersBackground: $tg-250;
$pageContentBackground: $tg-250;
$secondaryheaderBackground: $tg-250;
$settingsBackgroundUnavailable: $tg-300;
$groupBackgroundColor:  $tg-350;
$product-background: $tbg-800;
$subMenuBackground: $settingsBackgroundUnavailable;
$notificationsBackground: $ordersBackground;

// Borders
$menuBackgroundBorder: $tg-500;
$lightSeparatorColor: $tg-300;
$mediumSeparatorColor: $tg-400;
$darkSeparatorColor: $tg-600;




// Overlays
$overlayNotTransparant: $tg-300;
$overlay: rgba($overlayNotTransparant, 1);
$overlayTransparant: rgba($overlayNotTransparant, 0.95);
$stroke: $tg-250;

// Misc
$placeholder: $tg-500;
$groupFontColor: $tg-900;
$fadedText: $tg-500;
$slightlyFadedText: $tg-600;
$shadowColor: $tg-600;

$spacers: 1rem;

$space-xxs: 0.4rem;
$space-xs: 0.8rem;
$space-s: 1.2rem;
$space-m: 1.6rem;
$space-l: 2.4rem;
$space-xl: 3.6rem;
$space-xxl: 6.4rem;


@import './../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables';

